const loggedInUser = sessionStorage.getItem("user_token");
// local
// export const baseURL = "http://127.0.0.1:5000";

// Dev Live Server
let url = "https://caledontec.com/api"
if(loggedInUser != null){
    const foundUser = JSON.parse(loggedInUser);
    console.log("==foundUser.vendor_type==", foundUser.vendor_type)
    if(foundUser.vendor_type == 'CL'){
        url = "https://caledontec.com/api_cl";
    }
}
export const baseURL = url;
// export const baseURL = "https://routesrez.com/api";