import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "antd/dist/antd.css";
import { Calendar, Empty, DatePicker } from 'antd';
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
// import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Preview } from "@mui/icons-material";
import { MDBDataTable } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as XLSX from "xlsx";
import ReactDOM from "react-dom";
import DownloadIcon from "@mui/icons-material/Download";
import './rmsToRmp.css';

export default function DataTable() {
    const [company_id, setcompany_id] = useState("");
    const [rowsData, setRowsData] = useState([]);
    const [numDropdowns, setNumDropdowns] = useState(1);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [competitorsGrid, setCompetitorsGrid] = useState([["", ""]]);
    const [companyName, setCompanyName] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);


    const [isEnabled, setIsEnabled] = useState(false);

    const [selectedPickUpTime, setSelectedPickUpTime] = useState('');
    const [selectedRateParity, setSelectedRateParity] = useState('Static');
    const hours = 
    [
        { "name": "00:00" }, { "name": "00:30" }, { "name": "01:00" }, { "name": "01:30" }, { "name": "02:00" }, { "name": "02:30" }, { "name": "03:00" },
        { "name": "03:30" }, { "name": "04:00" }, { "name": "04:30" }, { "name": "05:00" }, { "name": "05:30" }, { "name": "06:00" }, { "name": "06:30" },
        { "name": "07:00" }, { "name": "07:30" }, { "name": "08:00" }, { "name": "08:30" }, { "name": "09:00" }, { "name": "09:30" }, { "name": "10:00" },
        { "name": "10:30" }, { "name": "11:00" }, { "name": "11:30" }, { "name": "12:00" }, { "name": "12:30" }, { "name": "13:00" }, { "name": "13:30" },
        { "name": "14:00" }, { "name": "14:30" }, { "name": "15:00" }, { "name": "15:30" }, { "name": "16:00" }, { "name": "16:30" }, { "name": "17:00" },
        { "name": "17:30" }, { "name": "18:00" }, { "name": "18:30" }, { "name": "19:00" }, { "name": "19:30" }, { "name": "20:00" }, { "name": "20:30" },
        { "name": "21:00" }, { "name": "21:30" }, { "name": "22:00" }, { "name": "22:30" }, { "name": "23:00" }, { "name": "23:30" }, { "name": "23:59" },
    ];
    const [trueKeys, setTrueKeys] = useState([]);
    const [customOptions, setCustomOptions] = useState([])
    const [customStrategy, setCustomStrategy] = useState("");
    const [selectedRateSourceOption, setSelectedRateSourceOption] = useState('');
    const [selectedCompetitionOption, setSelectedCompetitionOption] = useState('SIPP CODE');
    const [instantFromDate, setInstantFromDate] = useState(new Date());
    const [instantToDate, setInstantToDate] = useState(new Date());
    const [numbers, setNumbers] = useState({ "1": false, "2": false, '3': false, '4': false, '5': false, '6': false, 'Weekly': false, 'Monthly': false })
    const handleSelectAll = () => {
        const areAllSelected = Object.values(numbers).every((value) => value);
        const updatedNumbers = {};

        for (const key in numbers) {
            updatedNumbers[key] = !areAllSelected;
        }

        setNumbers(updatedNumbers);
    };

    const handleDownload = () => {
      const ws = XLSX.utils.json_to_sheet(tableData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Automation Results");
      XLSX.writeFile(wb, "automation_results.xlsx");
    };

    const handleCustomStrategy = (value) => {
        setCustomStrategy(value);
        console.log('strategyValue', value)
    };
    const handleNumberSelection = (number) => {
        setNumbers(prevNumbers => ({
            ...prevNumbers,
            [number]: !prevNumbers[number], // Toggle the value for the clicked number
        }));

        setTrueKeys(prevTrueKeys => {
            // If the number is already in the array, remove it (deselected)
            if (prevTrueKeys.includes(number)) {
                return prevTrueKeys.filter(n => n !== number);
            } else {
                // Otherwise, add it (selected)
                return [...prevTrueKeys, number];
            }
        });
    };
    const handleParity = (value) => {
        setSelectedRateParity(value);
    };

    const [isReadyToDownload, setIsReadyToDownload] = useState(false);
    const [tableData, setTableData] = useState([]);

    const handleAutomationComplete = (newTableData) => {
        setTableData(newTableData);
        setIsReadyToDownload(false); // Reset download state
    };

    useEffect(() => {
        console.log('tableData444',tableData)
        setTableData(tableData)
    }, [tableData]);

    const parityOptions = 
    [
        { value: "Static", label: "Static" },
        { value: "Multiple of LOR", label: "Multiple of LOR" }
    ];
    const rateSourceOptions = 
    [
        { value: "Expedia", label: "Expedia" },
        { value: "Priceline", label: "Priceline" }
    ];
    const handleCompetitionOption = (value) => {
        setSelectedCompetitionOption(value);
        if(value == "SIPP CODE"){
            // setCarDetailsInOption1(carDetailsInOptionConstant)
            // setCarDetailsInOption2(fleetStandardCarDetails)
        }else if(value == "Car Category"){
            if(selectedRateSourceOption == "Expedia"){
                // setCarDetailsInOption1(expediaOptions)
                // setCarDetailsInOption2(expediaOptions)
            }else if (selectedRateSourceOption == "Priceline"){
                // setCarDetailsInOption1(pricelineOptions)
                // setCarDetailsInOption2(pricelineOptions)
            }    
        }
    };
    const handleRateSourceOption = (value) => {

        axios.post(baseURL + "/get_custom_strategy_location_ota", {
            location_id: locationId,
            selectedOTA: value  // Add your second parameter here
        })
        .then(response => {
            console.log('strategy Response', response)
            if(response.data !== ''){
                const newOptions = response.data.map(record => record.strategyName);
                setCustomOptions(newOptions);
            }
        }) 
        setSelectedRateSourceOption(value);
    };
    const handlePickUpTime = (value) => {
        setSelectedPickUpTime(value);
    };

    const handleToggle = () => {
        setIsEnabled(!isEnabled); 
    };

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const handleSearch = (e) => {
      const query = e.target.value.toLowerCase();
      const filteredData = originalData.filter((row) => {
        return (
          row.pStartDate.toLowerCase().includes(query) ||
          row.pLor.toLowerCase().includes(query) ||
          row.pSippCode.toLowerCase().includes(query) ||
          row.pCompetitorName.toLowerCase().includes(query) ||
          row.pCompSippCode.toLowerCase().includes(query)
        );
      });
      setTableData(filteredData);
    };

    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);

    const handleAddEmail = () => {
        if (email && !emailList.includes(email)) {
            setEmailList([...emailList, email]);
            setEmail(""); // Clear the input field
        }
    };

    const handleRemoveEmail = (removeEmail) => {
        setEmailList(emailList.filter((e) => e !== removeEmail));
    };

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            setLocationDetail([{}])
            setNumDropdowns(1);
            setTableData([]);
            setOriginalData([]);
            setCompetitorsGrid([["", ""]]);
            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        setTableData([]);
        setOriginalData([]);
        setSelectedRateSourceOption('')
        setCustomStrategy('')
        setCustomOptions([])
        if (locationName != "") {
        temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
        setLocationId(temp_location_id);
        axios
            .get(baseURL + "/get_automation_weekly_report/" + locationName)
            .then((res) => {
                console.log('res',res)
                if(res.data){
                    setEmailList(res.data.email_list || []);
                    setIsEnabled(res.data.is_weekly_report_enabled)
                }  
            });
        }
    }, [locationName]);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateUpdate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }
    }, []);


    // const downloadExcel = () => {
    //     const worksheet = XLSX.utils.json_to_sheet(tableData);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Competitor Data");
    //     XLSX.writeFile(workbook, "Competitor_Data.xlsx");
    // };

    const downloadExcel = (newTableData) => {
        console.log("Table Data for Excel:", newTableData);  // Check if data exists here

        if (newTableData.length === 0) {
            Swal.fire({
                position: "middle",
                icon: "error",
                title: "No data available for download",
                showConfirmButton: true,
                timer: 5000,
            });
            return;
        }

        // Generate Excel file from tableData
        const worksheet = XLSX.utils.json_to_sheet(newTableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Competitor Data");
        XLSX.writeFile(workbook, "Competitor_Data.xlsx");
    };

    

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!companyName || !locationName) {
            Swal.fire("Please select both Account Name and Location Name.");
            return;
        }

        if (emailList.length === 0) {
            Swal.fire("Please add at least one email ID.");
            return;
        }
        const isWeeklyReportEnabled = isEnabled; 

        const body_payload = {
            account_id: company_id.toString(),
            location_name: locationName,
            location_id: locationId.toString(),
            is_weekly_report_enabled:isWeeklyReportEnabled,
            company_name: companyName,
            email_list: emailList,
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        console.log('body_payload',body_payload)
        axios.post(baseURL + "/save_automation_weekly_report_info", body_payload).then((res) => {
            Swal.fire({
                position: "middle",
                icon: "success",
                title: "Weekly Report saved Successfully",
                showConfirmButton: false,
                timer: 3000,
            });
        });

        setCompanyName('');
        setLocationName('');
        setEmail('');
        setEmailList([]);
        setIsEnabled(false);
    };

    const handleRmsToRmpSubmit = (e) => {
        e.preventDefault();
        // setRowData({})

        // setResponseData('');
        if (companyName === "") {
            Swal.fire('Please select Account Name');
            return;
        }
        if (locationName === "") {
            Swal.fire('Please select a Location to proceed');
            return;
        }
        const hasTrueValueForLor = Object.values(numbers).some((value) => value === true);
        const trueKeys2 = Object.keys(numbers).filter(key => numbers[key] === true);
        setTrueKeys(trueKeys2)
        if (selectedPickUpTime === "") {
            Swal.fire('Please select a PickUp Time');
            return;
        }
        if (!hasTrueValueForLor) {
            Swal.fire("Please select atleast one Lor");
            return;
        }
        if (selectedRateSourceOption == ''){
            Swal.fire("Please select Rate Source");
            return;
        }

        if (customStrategy == ''){
            Swal.fire("Please select Strategy");
            return;
        }

        // const momentObject = Moment(instantFromDate);
        // const formattedDate = momentObject.format('MMMM Do, YYYY');
        // setHeaderData(locationName + ' - ' + formattedDate)
        // scrollToBottom();
        // if (selectedCurrencyOption == 'USD') {
        //     setCurrencyConversionValue(1)
        // }
        const body_payload =
        {
            location_name: locationName,
            account_id: company_id.toString(),
            location_id: locationId.toString(),
            pickup_time: selectedPickUpTime,
            rateSourceSelected: selectedRateSourceOption,
            paritySelected: selectedRateParity,
            lor: numbers,
            strategy: customStrategy,
            from_date:new Date(instantFromDate).toDateString(),
            to_date: new Date(instantToDate).toDateString(),
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        // setLoading(true);
        console.log('body_payload',body_payload)
        setIsLoading(true);
        // Define the downloadExcel function globally (outside of the modal HTML content)
        axios
        .post(baseURL + "/save_automation_info_rms_to_rmp", body_payload)
        .then((res2) => {
            console.log("RES2:", res2);
            setIsLoading(false);
            
            if (res2.data && res2.data.pStartDate) {
                // Fill tableData with the relevant response data
                const newTableData = res2.data.pStartDate.map((_, i) => ({
                    pStartDate: res2.data.pStartDate[i],
                    pLor: res2.data.pLor[i],
                    pStrategy: res2.data.pStrategy[i],
                    pSippCode: res2.data.pSippCode[i],
                    pCompSippCode: res2.data.pCompSippCode[i],
                    pCompetitorPrice: res2.data.pCompetitorPrice[i],
                    pOurPrice: res2.data.pOurPrice[i],
                    pMinimumRate: res2.data.pMinimumRate[i],
                    pRangeValue: res2.data.pRangeValue[i],
                    pCompetitorName: res2.data.pCompetitorName[i],
                }));
                
                console.log("New Table Data:", newTableData);  // Log the populated tableData

                if (newTableData.length === 0) {
                    console.log("New Table Data22 length:", newTableData.length);
                    console.log("New Table Data22:", newTableData);

                    Swal.fire({
                        position: "middle",
                        icon: "error",
                        title: "No data available for download666",
                        showConfirmButton: true,
                        timer: 5000,
                    });
                    return;
                }
                setTableData(newTableData);
                setOriginalData(newTableData);

                // Trigger the Swal modal with the download button
                // Swal.fire({
                //     position: "middle",
                //     icon: "success",
                //     title: `<span id="swal-title">Automation Completed Successfully</span> <span id="downloadIcon"></span>`,
                //     showConfirmButton: false,
                //     showCloseButton: true,
                //     didOpen: () => {
                //         const downloadIconContainer = document.getElementById("downloadIcon");
                //         if (downloadIconContainer) {
                //             ReactDOM.render(
                //                 <button
                //                     style={{ background: "none", border: "none", cursor: "pointer" }}
                //                     onClick={() => downloadExcel(newTableData)}
                //                     // onClick={downloadExcel}
                //                 >
                //                     <DownloadIcon fontSize="large" />
                //                 </button>,
                //                 downloadIconContainer
                //             );
                //         }
                //     },
                //     html: `
                //         <div style="height: 360px; overflow:auto;">
                //             <table border="1" style="border-collapse: collapse;">
                //                 <tr>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Start Date</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Lor</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Routes Sipp Code</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Competitor Name</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Competitor Sipp Code</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Competitor Rate</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Our Final Rate</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Range Value</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Strategy</th>
                //                     <th style="border: 1px solid #000;width: 120px; position: sticky; top: 0; background-color: #fff;">Is from Minimum Rate</th>
                //                 </tr>
                //                 ${newTableData.map((rowData) => `
                //                     <tr>
                //                         <td style="border: 1px solid #000;">${rowData.pStartDate}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pLor}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pSippCode}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pCompetitorName}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pCompSippCode}</td>
                //                         <td style="border: 1px solid #000;">
                //                             ${isNaN(rowData.pCompetitorPrice) || rowData.pCompetitorPrice === '-' 
                //                                 ? 'N/A' 
                //                                 : parseFloat(rowData.pCompetitorPrice).toFixed(2)}
                //                         </td>
                //                         <td style="border: 1px solid #000;">
                //                             ${isNaN(rowData.pOurPrice) || rowData.pOurPrice === '-' 
                //                                 ? 'N/A' 
                //                                 : parseFloat(rowData.pOurPrice).toFixed(2)}
                //                         </td>
                //                         <td style="border: 1px solid #000;">${rowData.pRangeValue}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pStrategy}</td>
                //                         <td style="border: 1px solid #000;">${rowData.pMinimumRate}</td>
                //                     </tr>
                //                 `).join('')}
                //             </table>
                //         </div>
                //     `,
                // });
            } else {
              Swal.fire({
                position: "middle",
                icon: "error",
                title: res2.data,
                // title: "No data available",
                showConfirmButton: true,
                timer: 5000,
            });
                // Swal.fire({
                //     position: "middle",
                //     icon: "error",
                //     title: res2.data,
                //     showConfirmButton: false,
                //     timer: 8000,
                // });
            }
        });
    };



  return (
    <div className="container mt-4 p-4 bg-light rounded shadow-lg border">
        {/* Account Name */}
        <div className="row mb-3 border-bottom pb-3">
          <div className="col-md-2">
            <label className="form-label fw-bold">Account Name</label>
          </div>
          <div className="col-md-4">
            <Select
              value={{ value: companyName, label: companyName || "Select Account Name" }}
              options={companyNameOptions}
              onChange={handleInputChangeCompanyName}
            />
          </div>
        </div>

        {/* Location Name */}
        <div className="row mb-3 border-bottom pb-3">
          <div className="col-md-2">
            <label className="form-label fw-bold">Location Name</label>
          </div>
          <div className="col-md-4">
            <Select
              value={{ value: locationName, label: locationName || "Select Location Name" }}
              options={locationNameOptions}
              onChange={handleInputChangeLocationName}
            />
          </div>
        </div>
        {/* PickUp Time */}
        <div className="row mb-3 border-bottom pb-3">
          <div className="col-md-2">
            <label className="form-label fw-bold">PickUp Time</label>
          </div>
          <div className="col-md-2">
            <select className="form-select text-center" value={selectedPickUpTime} onChange={(e) => handlePickUpTime(e.target.value)}>
              <option value="">Select Time</option>
              {hours.map((val) => (
                <option value={val.name} key={val.name}>{val.name}</option>
              ))}
            </select>
          </div>
        </div>

        {/* From Date / To Date */}
          <div className="row mb-3 border-bottom pb-3">
            <div className="col-md-2">
              <label htmlFor="fromDate" className="form-label fw-bold">
                <b>From Date</b>
              </label>
            </div>
            {/* <div className="col-md-4"> */}
            <div className="site-calendar-demo-card col-md-4" >
              <Calendar
                fullscreen={false}
                value={moment(instantFromDate)} // Use moment to handle Date objects
                onChange={(value) => {
                  setInstantFromDate(value);
                  setInstantToDate(value); // Set "To Date" as same as "From Date"
                }}
                disabledDate={(current) => {
                  // Disable past dates for "From Date"
                  return current && current.valueOf() < (new Date().setHours(0, 0, 0, 0));
                }}
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="toDate" className="form-label fw-bold">
                <b>To Date</b>
              </label>
            </div>
            <div className="site-calendar-demo-card col-md-4">
              <Calendar
                fullscreen={false}
                value={moment(instantToDate)} // Use moment for "To Date"
                onChange={(value) => { setInstantToDate(value); }} // Handle "To Date" change
                disabledDate={(current) => {
                      if (instantFromDate) {
                          const startDate = moment(instantFromDate);
                          if (loggedInUserDetail.userEmail === 'kjs@routes.ca') {
                              const endDate = moment(instantFromDate).add(20, 'days'); // Allow selection within 21 days of start date
                              return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                          }else{
                              const endDate = moment(instantFromDate).add(6, 'days');// Allow selection within 7 days of start date
                              return current && (current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf());
                          }
                      }
                      else {
                          return current && current.valueOf() < (new Date().valueOf() - (1 * 24 * 60 * 60));
                      }
                  }} 
              />
            </div>
          </div>

          {/* LOR's */}
          <div className="row mb-3 border-bottom pb-3">
            <div className="col-md-2">
              <label className="form-label fw-bold">LOR's</label>
            </div>
            <div className="col-md-10">
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div className="d-flex align-items-center mb-2 mb-md-0 me-md-4">
                  <input 
                    type="checkbox" 
                    className="form-check-input me-2" 
                    checked={Object.values(numbers).every(Boolean)} 
                    onChange={handleSelectAll} 
                  />
                  <span>Select All</span>
                </div>
                <div className="d-flex flex-wrap">
                  {Object.entries(numbers).map(([number, selected]) => (
                    <div key={number} className="d-flex align-items-center me-3 mb-2">
                      <input 
                        type="checkbox" 
                        className="form-check-input me-1" 
                        checked={selected} 
                        onChange={() => handleNumberSelection(number)} 
                      />
                      <span>{number}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Rate Source & Range Value Multiplier */}
          <div className="row mb-3 border-bottom pb-3">
              <div className="col-md-2">
                <label className="form-label fw-bold">Rate Source</label>
              </div>
              <div className="col-md-4">
                <select className="form-select text-center" value={selectedRateSourceOption} onChange={(e) => handleRateSourceOption(e.target.value)}>
                    <option value="">Select Rate Source</option>
                    {rateSourceOptions.map((val) => (
                    <option value={val.value} key={val.value}>{val.value}</option>
                    ))}
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label fw-bold">Range Value Multiplier</label>
              </div>
              <div className="col-md-4">
                <select className="form-select text-center" value={selectedRateParity} onChange={(e) => handleParity(e.target.value)}>
                    {parityOptions.map((val) => (
                    <option value={val.value} key={val.value}>{val.value}</option>
                    ))}
                </select>
              </div>
          </div>
          {/* Strategy */}
          <div className="row mb-3 border-bottom pb-3">
              <div className="col-md-2">
              <label className="form-label fw-bold">Strategy</label>
              </div>
              <div className="col-md-4">
              <select className="form-select text-center" value={customStrategy} onChange={(e) => handleCustomStrategy(e.target.value)}>
                  <option value="">Select Strategy</option>
                  {customOptions.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                  ))}
              </select>
              </div>
          </div>

          <div className="container mt-4 p-4 bg-light rounded shadow-lg border">
          {/* Full-screen loader overlay */}
          {isLoading && (
            <div className="loader-overlay">
                <div className="loader-content">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <div className="loader-text">
                    Please wait while we fetch the live rates as per your chosen strategy from RateMatePro...
                  </div>
                </div>
            </div>
          )}
          {/* Submit Button */}
          <div className="row">
              <div className="col-md-12 text-center">
              <button className="btn btn-success px-4" onClick={handleRmsToRmpSubmit} disabled={isLoading}>
                  {isLoading ? 'Processing...' : 'Submit'}
              </button>
              </div>
          </div>
        </div>

        {/* Automation Results Table & Download Button */}
        {tableData.length > 0 ? (
  <div className="row mt-4">
    <div className="col-md-12">
      {/* Header and Download Button */}
      <div className="row mt-3 mb-3">
        <div className="col-md-8 text-start">
          <h4>Automation Results</h4>
        </div>
        <div className="col-md-4 text-end">
          <button className="btn btn-primary" onClick={handleDownload}>
            Download Excel
          </button>
        </div>
      </div>

      {/* Search Bar */}
      <div className="row mb-3">
        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            onChange={handleSearch}  // Add your search handler function
          />
        </div>
      </div>

      {/* Table */}
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Start Date</th>
              <th className="text-center">Lor</th>
              <th className="text-center">Routes Sipp Code</th>
              <th className="text-center">Competitor Name</th>
              <th className="text-center">Competitor Sipp Code</th>
              <th className="text-center">Competitor Rate</th>
              <th className="text-center">Our Final Rate</th>
              <th className="text-center">Range Value</th>
              <th className="text-center">Strategy</th>
              <th className="text-center">Is from Minimum Rate</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td className="text-center">{row.pStartDate}</td>
                <td className="text-center">{row.pLor}</td>
                <td className="text-center">{row.pSippCode}</td>
                <td className="text-center">{row.pCompetitorName}</td>
                <td className="text-center">{row.pCompSippCode}</td>
                <td className="text-center">
                  {isNaN(row.pCompetitorPrice) || row.pCompetitorPrice === '-'
                    ? 'N/A'
                    : parseFloat(row.pCompetitorPrice).toFixed(2)}
                </td>
                <td className="text-center">
                  {isNaN(row.pOurPrice) || row.pOurPrice === '-'
                    ? 'N/A'
                    : parseFloat(row.pOurPrice).toFixed(2)}
                </td>
                <td className="text-center">{row.pRangeValue}</td>
                <td className="text-center">{row.pStrategy}</td>
                <td className="text-center">{row.pMinimumRate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
) : (
  <div className="row mt-4">
    <div className="col-md-12 text-center">
      <h4>No results found</h4>
    </div>
  </div>
)}

{/* Always visible Search Bar */}
<div className="row mb-3">
  <div className="col-md-12">
    <input
      type="text"
      className="form-control"
      placeholder="Search..."
      onChange={handleSearch}  // Keep this always visible
    />
  </div>
</div>


        
        {/* </div>
    );
}; */}

    </div>
  );}
