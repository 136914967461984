import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import Chart3 from "./Chart3";
import Chart6 from "./Chart6";
import PieCharts_1_1 from "./PieCharts_1_1";
import PieCharts_1_2 from "./PieCharts_1_2";
import PieCharts_2_1 from "./PieCharts_2_1";
import PieCharts_2_2 from "./PieCharts_2_2";
import PieCharts_3_1 from "./PieCharts_3_1";
import PieCharts_3_2 from "./PieCharts_3_2";
// import { BarChart } from "recharts";
import { BarChart } from "./BarChart";
import { Button, Typography } from "@mui/material";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
import { BarCharts } from "./ComposedChart";
import DropChart from "./Drop-off";
import PickUp from "./PickUp";
import Modal from 'react-bootstrap/Modal'
// import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
// import BreadCrumb from "./BreadCrumb";

const Dashboard = () => {
  var temp_location_id = "";
  const [companyDetail, setcompanyDetail] = useState([{}]);
  const [companyName, setCompanyName] = useState("");
  const [company_id, setcompany_id] = useState("");
  const [locationDetail, setLocationDetail] = useState([{}]);
  const [locationName, setLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
  const [showingChartOf, setShowingChartOf] = useState("28 days");
  const [showingChartOf2, setShowingChartOf2] = useState("28 days");
  const [reservationDetail_total, setReservationDetail_total] = useState([]);
  const [reservationDetail_valid, setReservationDetail_valid] = useState([]);
  const [reservationDetail_cancelled, setReservationDetail_cancelled] = useState([]);
  const [reservationDetail_noShow, setReservationDetail_noShow] = useState([]);
  const [pickUpDetail, setPickUpDetail] = useState([]);
  const [dropOffDetail, setDropOffDetail] = useState([]);

  const [chartdata1, setChartData1] = useState();
  const [chartdata2, setChartData2] = useState();
  const [chartdata3, setChartData3] = useState();
  const [chartdata4, setChartData4] = useState();
  const [chartdata5, setChartData5] = useState();
  const [chartdata6, setChartData6] = useState();
  const [chartdata7, setChartData7] = useState([]);
  const [barChartInventory, setBarChartInventory] = useState([]);

  //API call
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user_token");
    const foundUser = JSON.parse(loggedInUser);
    setLoggedInUserDetail(foundUser);

    if (foundUser.role === "SuperAdmin") {
      axios.get(baseURL + "/get_company_details_for_users").then((res) => {
        // (res.data).push({ _id: "all_affiliate", account_type: 'all_affiliate', affiliated_to: null, affiliated_to_id: null, company_name: 'All Affiliates' })
        const updatedData = [...res.data];
        if (foundUser.vendor_type !== "CL") {
            updatedData.push({
                _id: "all_affiliate",
                account_type: "all_affiliate",
                affiliated_to: null,
                affiliated_to_id: null,
                company_name: "All Affiliates",
            });
        }

        setcompanyDetail(res.data);
      });
    } else {
      fetch(
        baseURL +
        "/get_company_details_for_users_accessWise/" +
        foundUser.access["Dashboard_Account Access"] +
        "*" +
        foundUser.account_id +
        "*" +
        foundUser.account_type.account_type
      )
        .then((res) => res.json())
        .then((data) => {
          if(foundUser.account_type.account_type != 'Affiliate'){
            if(foundUser.vendor_type != 'CL')
            {
              (data).push({ _id: "all_affiliate", account_type: 'all_affiliate', affiliated_to: null, affiliated_to_id: null, company_name: 'All Affiliates' })
            }
          }
          setcompanyDetail(data);
        });
    }
  }, []);


  //Trigger on Company Name Dropdown Changes , getting Company ID
  useEffect(() => {
    if (companyDetail && companyName) {

      setBarChartInventory([]);
      setLocationDetail([]);

      let obj_company_detail = companyDetail.filter(
        (value) => value.company_name === companyName
      )[0];
      let temp_company_id = obj_company_detail._id;
      let company_type = obj_company_detail.account_type;
      setcompany_id(temp_company_id);


      if (temp_company_id === 'all_affiliate') {
        //API call for getting data from DB
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        fetch(
          baseURL + "/get_reservations_for_dashboard_all_affiliates",
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            // console.log("----data all afflt---");
            // console.log(data);

            let arr1 = data[0];
            let arr2 = data[1];
            let arr3 = data[2];
            let arr4 = data[3];

            setChartData1(arr1);
            setReservationDetail_total(arr1);

            setChartData2(arr2);
            setReservationDetail_valid(arr2);

            setChartData3(arr3);
            setReservationDetail_cancelled(arr3);

            setChartData6(arr4);
            setReservationDetail_noShow(arr4);

          }); //api hit ends



        // Pick Up 
        fetch(
          baseURL + "/get_pickUp_for_dashboard_all_affiliates",
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            let arr1 = data;

            setChartData4(arr1);
            setPickUpDetail(arr1);

          }); //Pick up ends


        // DropOff 
        fetch(
          baseURL + "/get_dropOff_for_dashboard_all_affiliates",
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            let arr2 = data;

            setChartData5(arr2);
            setDropOffDetail(arr2);


          }); //DropOff up ends
      }
      else {

        //API call for getting data from DB
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };

        fetch(
          baseURL + "/get_company_location/" + temp_company_id,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {
            let allTemp = data.res;
            if (loggedInUserDetail.role === "SuperAdmin") {
              if (allTemp.length > 0) {
                setLocationDetail(allTemp);
                setLocationName("");
                setLocationId("");
              }
            } else if (
              company_type === "Affiliate" &&
              loggedInUserDetail.account_type.account_type === "Corporate"
            ) {
              if (allTemp.length > 0) {
                setLocationDetail(allTemp);
                setLocationName("");
                setLocationId("");
              }
            } else {
              axios
                .post(baseURL + "/get_individual_user_details", {
                  user_email: loggedInUserDetail.userEmail,
                })
                .then((data) => {
                  if (allTemp.length > 0) {
                    allTemp = allTemp.filter(
                      (val) =>
                        val !== undefined &&
                        data.data[0].location_allocated[val.location_id]
                    );

                    setLocationDetail(allTemp);
                    setLocationName("");
                    setLocationId("");
                  }
                });
            }
          });

        fetch(
          baseURL + "/get_reservations_for_dashboard/" + temp_company_id,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            let arr1 = data[0];
            let arr2 = data[1];
            let arr3 = data[2];
            let arr4 = data[3];

            setChartData1(arr1);
            setReservationDetail_total(arr1);

            setChartData2(arr2);
            setReservationDetail_valid(arr2);

            setChartData3(arr3);
            setReservationDetail_cancelled(arr3);

            setChartData6(arr4);
            setReservationDetail_noShow(arr4);

          }); //api hit ends



        // Pick Up 
        fetch(
          baseURL + "/get_pickUp_for_dashboard/" + temp_company_id,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            let arr1 = data;

            setChartData4(arr1);
            setPickUpDetail(arr1);

          }); //Pick up ends


        // DropOff 
        fetch(
          baseURL + "/get_dropOff_for_dashboard/" + temp_company_id,
          requestOptions
        )
          .then((res) => res.json())
          .then((data) => {

            let arr2 = data;

            setChartData5(arr2);
            setDropOffDetail(arr2);


          }); //DropOff up ends

      }

    } //final if ends here
  }, [companyName]);



  useEffect(() => {
    if (locationName && locationDetail) {
      setChartData1([]);
      setReservationDetail_total([]);
      setChartData2([]);
      setReservationDetail_valid([]);
      setChartData3([]);
      setReservationDetail_cancelled([]);
      setBarChartInventory([])

      temp_location_id = locationDetail
        .filter((loc) => loc.location_name === locationName)
        .map((val) => val.location_id);
      setLocationId(temp_location_id);

      const location_code = temp_location_id.toString().split("-")[1]

      //API call for getting data from DB
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };


      fetch(
        baseURL + "/get_reservations_for_dashboard_for_location/" + company_id + "*" + temp_location_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {

          let arr1 = data[0];
          let arr2 = data[1];
          let arr3 = data[2];
          let arr4 = data[3];

          setChartData1(arr1);
          setReservationDetail_total(arr1);

          setChartData2(arr2);
          setReservationDetail_valid(arr2);

          setChartData3(arr3);
          setReservationDetail_cancelled(arr3);

          setChartData6(arr4);
          setReservationDetail_noShow(arr4);

        }); //api hit ends


      // Pick Up 
      fetch(
        baseURL + "/get_pickUp_for_dashboard_for_location/" + company_id + "*" + temp_location_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {

          let arr1 = data;

          setChartData4(arr1);
          setPickUpDetail(arr1);


        }); //Pick up ends


      // DropOff 
      fetch(
        baseURL + "/get_dropOff_for_dashboard_for_location/" + company_id + "*" + temp_location_id,
        requestOptions
      )
        .then((res) => res.json())
        .then((data) => {

          let arr2 = data;

          setChartData5(arr2);
          setDropOffDetail(arr2);


        }); //DropOff up ends

    }
  }, [locationName]);

  //Option for company name
  const companyNameOptions = companyDetail.map((value) => ({
    value: value.company_name,
    label: value.company_name,
  }));

  const handleInputChangeCompanyName = (characterEntered) => {
    setCompanyName(characterEntered.value);
  };
  //Option for Location name
  const locationNameOptions = locationDetail.map((value) => ({
    value: value.location_name,
    label: value.location_name != undefined && value.location_name + " - " + value.location_id.toString().trim().split("-")[1]
  }));

  const handleInputChangeLocationName = (characterEntered) => {
    setLocationName(characterEntered.value);
  };

  // Chart data Button
  function setFirstButton() {
    // let arr1 = [];
    // for (let i = ele1_1.length - 1; i >= 0; i--) {
    //   arr1.push(ele1_1[i]);
    // }
    setChartData1(ele1_1);

    // let arr2 = [];
    // for (let i = ele1_2.length - 1; i >= 0; i--) {
    //   arr2.push(ele1_2[i]);
    // }
    setChartData2(ele1_2);

    // let arr3 = [];
    // for (let i = ele1_3.length - 1; i >= 0; i--) {
    //   arr3.push(ele1_3[i]);
    // }
    setChartData3(ele1_3);

    // let arr4 = [];
    // for (let i = ele1_4.length - 1; i >= 0; i--) {
    //   arr4.push(ele1_4[i]);
    // }
    setChartData6(ele1_4);

    setShowingChartOf("7 days");
  }

  function setSecondButton() {
    // let arr1 = [];
    // for (let i = elem1_1.length - 1; i >= 0; i--) {
    //   arr1.push(elem1_1[i]);
    // }
    setChartData1(elem1_1);

    // let arr2 = [];
    // for (let i = elem1_2.length - 1; i >= 0; i--) {
    //   arr2.push(elem1_2[i]);
    // }
    setChartData2(elem1_2);

    // let arr3 = [];
    // for (let i = elem1_3.length - 1; i >= 0; i--) {
    //   arr3.push(elem1_3[i]);
    // }
    setChartData3(elem1_3);

    // let arr4 = [];
    // for (let i = elem1_4.length - 1; i >= 0; i--) {
    //   arr4.push(elem1_4[i]);
    // }
    setChartData6(elem1_4);
    setShowingChartOf("14 days");
  }

  function setThirdButton() {
    // let arr1 = [];
    // for (let i = elem2_1.length - 1; i >= 0; i--) {
    //   arr1.push(elem2_1[i]);
    // }
    setChartData1(elem2_1);

    // let arr2 = [];
    // for (let i = elem2_2.length - 1; i >= 0; i--) {
    //   arr2.push(elem2_2[i]);
    // }
    setChartData2(elem2_2);

    // let arr3 = [];
    // for (let i = elem2_3.length - 1; i >= 0; i--) {
    //   arr3.push(elem2_3[i]);
    // }
    setChartData3(elem2_3);

    // let arr4 = [];
    // for (let i = elem2_4.length - 1; i >= 0; i--) {
    //   arr4.push(elem2_4[i]);
    // }
    setChartData6(elem2_4);
    setShowingChartOf("28 days");
  }

  const ele1_1 = reservationDetail_total.slice(0, 7);
  const elem1_1 = reservationDetail_total.slice(0, 14);
  const elem2_1 = reservationDetail_total.slice(0, 28);

  const ele1_2 = reservationDetail_valid.slice(0, 7);
  const elem1_2 = reservationDetail_valid.slice(0, 14);
  const elem2_2 = reservationDetail_valid.slice(0, 28);

  const ele1_3 = reservationDetail_cancelled.slice(0, 7);
  const elem1_3 = reservationDetail_cancelled.slice(0, 14);
  const elem2_3 = reservationDetail_cancelled.slice(0, 28);

  const ele1_4 = reservationDetail_noShow.slice(0, 7);
  const elem1_4 = reservationDetail_noShow.slice(0, 14);
  const elem2_4 = reservationDetail_noShow.slice(0, 28);

  // PickUp DropOff Chart data Button
  function setFirstButton1() {
    setChartData4(item1_1);
    setChartData5(item2_1);

    console.log(item1_1)
    console.log(item2_1)
    setShowingChartOf2("7 days");
  }

  function setSecondButton2() {
    setChartData4(item1_2);
    setChartData5(item2_2);
    setShowingChartOf2("14 days");
  }

  function setThirdButton3() {
    setChartData4(item1_3);
    setChartData5(item2_3);
    setShowingChartOf2("28 days");
  }

  const item1_1 = pickUpDetail.slice(0, 7);
  const item1_2 = pickUpDetail.slice(0, 14);
  const item1_3 = pickUpDetail.slice(0, 28);

  const item2_1 = dropOffDetail.slice(0, 7);
  const item2_2 = dropOffDetail.slice(0, 14);
  const item2_3 = dropOffDetail.slice(0, 28);

  return (
    <>
    <div className="container">
      <div className="row pt-3">
        {/* <div className="col-12 Dashboard_header">Dashboard</div> */}
        <Typography variant="h4" sx={{ pb: 3 }}>
          Dashboard
        </Typography>
        <div className="col-12 mt-2">
          <h5>Select account and location to proceed ahead:</h5>
        </div>
        {/* Only for label of Account Name And Location Name */}
        <div className="row pt-2">
          <div className="col-12 d-flex">
            <div className="col-12 col-md-6">
              <label htmlFor="company_name" className="form-label">
                Account Name
              </label>
            </div>
            <div>
              <label htmlFor="company_name" className="form-label">
                Location Name
              </label>
            </div>
          </div>
        </div>
        {/* drop-down of Account Name & Location Name */}
        <div className="row">
          <div className="col-12 d-flex">
            <div className="col-md-4 mb-3">
              <Select
                value={{
                  value: companyName,
                  label: companyName ? companyName : "Select Account Name",
                }}
                inputId="company_name"
                name="company_name"
                options={companyNameOptions}
                onChange={handleInputChangeCompanyName}
              />
            </div>
            <div className="col-md-4 mb-3" style={{ marginLeft: "198px" }}>
              <Select
                value={{
                  value: locationName,
                  label: locationName ? locationName : "Select Location Name",
                }}
                inputId="location_name"
                name="location_name"
                options={locationNameOptions}
                onChange={handleInputChangeLocationName}
              />
            </div>
          </div>
        </div>
        {/* Charts and Pi-charts */}
        {companyName !== "" ? (
          <>
            {/* Booking chart start */}
            <div className=" row">
              <div className="Buttons">
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={setFirstButton}
                >
                  7 days
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={setSecondButton}
                  style={{ marginLeft: "10px" }}
                >
                  14 days
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{ marginLeft: "10px" }}
                  onClick={setThirdButton}
                >
                  28 days
                </button>
              </div>

              <div className="charts col-12 d-flex gap-5">
                <div className="col-6 mt-2">
                  <Chart1
                    title={showingChartOf}
                    aspect={2 / 1}
                    chartdata1={chartdata1}
                  />
                </div>
                <div className="col-6 mt-2">
                  <Chart3
                    title={showingChartOf}
                    aspect={2 / 1}
                    chartdata2={chartdata2}
                  />
                </div>
              </div>
            </div>
            <div className=" row">
              <div className="charts col-12 d-flex gap-5">
                <div className="col-6 mt-2">
                  {/* <Chart /> */}
                  <Chart2
                    title={showingChartOf}
                    aspect={2 / 1}
                    chartdata3={chartdata3}
                  />
                </div>
                {/* ota pi-chart */}
                <div className="col-6 mt-2">
                  <Chart6
                    title={showingChartOf}
                    aspect={2 / 1}
                    chartdata6={chartdata6}
                  />
                </div>

              </div>
            </div>
            {/* Booking chart ends */}

            {/* For Pick Up and Drop Off Start*/}
            <div className="row pt-6">
              <div className="Buttons">
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={setFirstButton1}
                >
                  7 days
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={setSecondButton2}
                  style={{ marginLeft: "10px" }}
                >
                  14 days
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{ marginLeft: "10px" }}
                  onClick={setThirdButton3}
                >
                  28 days
                </button>
              </div>
            </div>

            <div className="row">
              <div className="charts col-12 d-flex gap-5">
                <div className="col-6 mt-2">

                  <PickUp
                    title={showingChartOf2}
                    aspect={2 / 1}
                    chartdata4={chartdata4}
                  />
                </div>
                <div className="col-6 mt-2">

                  <DropChart
                    title={showingChartOf2}
                    aspect={2 / 1}
                    chartdata5={chartdata5}
                  />
                </div>
              </div>
            </div>
            {/* For Pick Up and Drop Off Ends*/}


            {/* FOR INVENTORY AND TOTAL CAR_CLASS as of Today */}
            <div className="row pt-4">
              <div className="charts col-12 d-flex gap-5">
                <div className="col-2 mt-2">
                </div>

                {/* Rate code pi-chart */}
                <div className="col-8" style={{ width: 280 }}>
                  {/* <BarChart barChartInventory={barChartInventory} /> */}
                </div>
                <div className="col-2"></div>
              </div>
            </div>

            {/* FOR INVENTORY AND TOTAL CAR_CLASS RESERVATION */}
            {/* <div className="row">
                    <div className="charts col-12 d-flex gap-5">
                      <div className="col-12 mt-2">
                        <BarCharts chartdata7={chartdata7}/>
                      </div>
                    </div>
                  </div>
             */}

            <div className="row mt-3"></div>

            <br />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  </>
  );
};

export default Dashboard;
